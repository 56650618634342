.emblem-modal {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(100, 100, 100, 0.5);
  z-index: 600;
}

.emblem-modal-window {
  position: absolute;
  height: max-content;
  max-height: max(calc(100% - 40px));
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  overflow: auto;
  border-radius: 4px;
  outline: currentcolor none medium;
  padding: 10px;
}

.close-modal {
  position: absolute;
  right: 10px;
}

.close-modal:hover {
  cursor: pointer;
}

.floatleft, .floatright {
  text-align: center;
}

@media only screen and (min-width: 31rem) {
  .emblem-modal-window {
    width: 28rem;
    margin-left: auto;
    margin-right: auto;
  }
}
