html,
body,
#root {
  height: 100%;
}

body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
