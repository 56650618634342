.app-container {
height: 100%;
}

.header-type-container {
  z-index: 500;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: solid 1px;
  border-radius: 34px;
  height: 28px;
  background: #ffffff;
  border-color: #ffffff;
}

.header-type-button {
  padding: 0;
  border: 0;
  height: 0;
}

.header-type-item {
  padding: 5px;
  color: #5f6369;
}

.header-type-item--active {
  background-color: #e3e3e8;
  border-radius: 34px;
}

@media (hover: hover) {
  .header-type-item:hover {
    color: #e3e3e8;
  }
}
