.emblem-container {
  display: flex;
}

.emblem-image-container {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emblem-image {
  position: absolute;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
}

.emblem-info-container {
  flex: 2;
  height: 7em;
  position: relative;
  overflow: hidden;
  color: #3a4570;
  text-decoration: none;
}

.emblem-info-container:hover {
  cursor: pointer;
}

.emblem-info-container::after {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: '';
  background: linear-gradient(rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 90%);
  pointer-events: none;
}

.emblem-info-container:hover::after {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: '';
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
}

.emblem-title {
  flex: 0 0 100%;
  font-size: 0.9rem;
}

.emblem-description {
  max-height: 5em;
  overflow: hidden;
  margin: 0px;
  font-size: 0.9rem;
  text-align: justify;
  line-height: 18px;
}

.emblem-description-more {
  margin: 0;
  text-align: center;
}
